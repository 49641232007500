import React, { useState } from 'react'
import Header from '../../components/header'
import SliderBan from '../../components/sliderBan'
import Advantages from '../../components/advantages'
import Definition from '../../components/definition'
import Graphics from '../../components/graphics'
import Benefits from '../../components/benefits'
import FAQ from '../../components/faq'

import WowManagerBanner1 from '../../images/wowManager/WowManagerBanner1.svg'
import WowManagerBanner2 from '../../images/wowManager/WowManagerBanner2.svg'
import FirstLeft from '../../images/wowManager/FirstLeft.svg'
import SecondLeft from '../../images/wowManager/SecondLeft.svg'
import ThirdLeft from '../../images/wowManager/ThirdLeft.svg'
import FourthLeft from '../../images/wowManager/FourthLeft.svg'
import FirstRight from '../../images/wowManager/FirstRight.svg'
import SecondRight from '../../images/wowManager/SecondRight.svg'
import ThirdRight from '../../images/wowManager/ThirdRight.svg'
import FourthRight from '../../images/wowManager/FourthRight.svg'
import LeftCardImg from '../../images/wowManager/LeftCardImg.svg'
import CenterCardImg from '../../images/wowManager/CenterCardImg.svg'
import RightCardImg from '../../images/wowManager/RightCardImg.svg'

import Manager from '../../images/wowManager/Manager.svg'
import GraphicsImg from '../../images/wowManager/ManagerGraphics.svg'
import BannerFoot from '../../components/bannerFoot'
import Footer from '../../components/footer'

import ResponsiveHead from '../../components/ResponsiveHead'
import { useStaticQuery, graphql } from 'gatsby'

export default () =>{

	const [open, setOpen] = useState(false);

	const data = useStaticQuery(graphql`
	{
		site {
		siteMetadata {
			WowManager{
					text
			}
		}
		}
	}`)

	const slidesInfo = [
		{
			image: WowManagerBanner1,
			titleHead: 'Wow Manager',
			descriptionHead: 'Gestão completa de contas, com insights para melhorar sua saúde financeira, a qualquer hora e em qualquer lugar, além das inúmeras vantagens Wow.',
			btnText: 'Eu quero!',
         urlBtn: 'https://www.wowmanager.com.br/signup'
		},
		{
			image: WowManagerBanner2,
			titleHead: 'Recarregue',
			descriptionHead: 'Fazer recargas para o seu pré-pago com a Wow Manager é simples e fácil! Recarregue na hora ou deixe agendado. ',
			btnText: 'Eu quero!',
         urlBtn: 'https://www.wowmanager.com.br/signup'
		}

	]

	// const titleHead = [ 'Wow Manager' ]

	// const descriptionHead = [ 'Gestão completa de contas, com insights para melhorar sua saúde financeira, a qualquer hora e em qualquer lugar, além das inúmeras vantagens Wow.' ]

	const leftItems = [
		{
			text: "Tenha várias contas, use as alçadas de permissão para controlar seu dinheiro, sua equipe e organizar sua vida.",
			image :{
				src: FirstLeft,
				alt: "First Left"
			}

		},
		{
			text: "Pagamentos e cobranças por boleto, link de cobrança e venda digitada em um só lugar. Recarga de Celular para qualquer operadora, a qualquer hora do dia.",
			image :{
				src: SecondLeft,
				alt: "Second Left"
			}

		},
		{
			text: "Receba alertas sobre seu negócio e acompanhe os fluxos de suas “subcontas”.",
			image :{
				src: ThirdLeft,
				alt: "Third Left"
			}

		},
		{
			text: "Tenha controle efetivo do caixa para um negócio, ou múltiplos negócios, de forma consolidada e centralizada.",
			image :{
				src: FourthLeft,
				alt: "Fourth Left"
			}

		}
	]

	const rightItems = [
		{
			text: "Controle seu saldo e compare as entradas e saídas, usando os filtros, gráficos e extratos que a Wow Manager oferece.",
			image :{
				src: FirstRight,
				alt: "First Right"
			}

		},
		{
			text: "Transferência entre contas Wow e para outros bancos.",
			image :{
				src: SecondRight,
				alt: "Second Right"
			}

		},
		{
			text: "Acompanhe as movimentações na sua linha do tempo, controle a performance por unidade ou região, o que foi efetivado, agendado ou está pendente.",
			image :{
				src: ThirdRight,
				alt: "Third Right"
			}

		},
		{
			text: "Realize pagamentos para seus funcionários, suas contas e seus fornecedores sem perder tempo com a Lista de Favorecidos e a Listagem de Últimas Transferências.",
			image :{
				src: FourthRight,
				alt: "Fourth Right"
			}

		}
	]

	const definitionTitle = "Wow Manager é..."

	const definitionSubTitle = "Uma conta digital inteligente"

	const definitionText = `Uma plataforma que te conecta com as informações mais importantes sobre seu dia a dia quando o assunto é dinheiro, 
	além de oferecer novos hábitos e novas soluções para que sua conta (seu bolso, sua carteira e seu negócio) nunca pare de crescer! 
	Sim, é o jeito mais fácil para você fazer a gestão do seu dinheiro e acompanhar toda a evolução da sua saúde financeira!`

	const graphicsTitle = "O melhor para vocÊ e seu negócio" 

	const graphicSubTitle = "Unifique. Automatize. Surpreenda-se. Aproveite." 

	const graphicsText = "Controle das ações efetivadas, agendadas e pendentes. Comparativo de entradas e saídas entre períodos. Gráficos e extratos por período (PDF e XLS Excel). Controle de saldo. Movimentações na linha do tempo. Controle de performance por unidade e região. Lista de favorecidos e últimas transferências." 

	const cards = [
		{
			title:'Gráficos',
			subTitle:'Total controle de seus gastos',
			text:'Transfira para contas Wow ou para qualquer banco de sua escolha, utilize dos gráficos de transferência e despesas para nunca mais se perder nos números. Faça transferência no mesmo dia ou agende, utilize a transferência em lote para transferências em grande volume.',
			img: LeftCardImg
		},
		{
			title: 'Alçadas',
			subTitle:'Crie usuários e gerencie sua equipe ',
			text:'Controle efetivo do caixa para um negócio ou múltiplos negócios de forma consolidada e centralizada. Você pode criar subcontas em sua plataforma Wow Manager para integrantes de sua empresa, limitando, assim, determinadas funções. Conte com quatro perfis distintos para criação das subcontas.',
			img: CenterCardImg
		},
		{
			title: 'Pagamentos',
			subTitle:'Pague suas contas de qualquer lugar',
			text:'Não espere seus boletos vencerem, pague a qualquer momento ou deixe agendado, tenha todo o poder de um extrato inteligente e fácil. Exporte listas de pagamentos em XLS ou PDF. ',
			img: RightCardImg
		}
	]

	const faqItems = [
		{
			title: "O que é a Wow Manager?",
			text: "A Wow Manager é uma solução Wow Solution que fornece uma plataforma e um app que reúnem os melhores serviços financeiros para você ter controle total, de forma simples e sem perder tempo! Seja para você ou sua empresa, ela oferece informações, dados e te deixa no comando. Você pode ter diversas contas, controlar todas em um só lugar e criar “subcontas” para cada setor e alçada de sua empresa."
		},
		{
			title: "Eu posso usar a Wow Manager para pagar meus funcionários ou meus fornecedores?",
			text: "Sim! Use a Wow para realizar pagamentos, bonificações e muito mais. A Wow também oferece cartões de crédito pré-pagos, físicos e virtuais. Assim, você consegue recarregar seus cartões ou transferir entre contas Wow na hora!"
		},
		{
			title: "Como funciona o controle de alçadas e atividades na Wow Manager?",
			text: "Sabemos que não é fácil administrar uma empresa, então, para te ajudar, oferecemos a criação de várias “subcontas” interligadas a uma conta principal. Essas “subcontas” são controladas pelo administrador da conta principal, que dá as permissões às “subcontas”. Assim, você consegue controlar quem agenda os pagamentos, quem consegue aprovar as movimentações, quem apenas visualiza e muito mais!"
		}
	]

	return(
		<>
			<Header open={open} setOpen={setOpen} currentPage='Wow Manager' />
			<ResponsiveHead open={open} setOpen={setOpen} />
			<SliderBan slides={slidesInfo} />
			<Benefits benefitsCards={cards} />
			<Definition title={definitionTitle} subTitle={definitionSubTitle} text={definitionText} img={Manager} justifyContent={"flex-end"}/>
			<Graphics title={graphicsTitle} subTitle={graphicSubTitle} description={graphicsText} img={GraphicsImg} />
			<Advantages leftItems={leftItems} rightItems={rightItems} />
			<FAQ items={faqItems}/>

			<BannerFoot>{data.site.siteMetadata.WowManager.text}</BannerFoot>

			<Footer />
		</>
	)
}